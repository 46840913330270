body {
  background-image: url("./images/main-1920x1080.jpg");
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@font-face {
  font-family: "MainsailCPC-Windy";
  src: local("MainsailCPC-Windy"),
    url("./fonts/MainsailCPC-Windy.otf") format("truetype");
}

svg {
  vertical-align: sub;
}

.headerLink {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;
  text-shadow: 0px 2px 2px #222;
  transition: font-size 0.2s ease;
  display: block;
  padding: 0.5rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.btn-primary {
  font-family: "MainsailCPC-Windy";
  letter-spacing: 1px;
}

.btn-primary {
  font-weight: bold;
}

.headerLink:hover {
  font-size: 1.7rem;
}

.headerButton {
  padding-top: 0.5rem;
}

.header-mobile {
  display: none;
  box-shadow: 0 1px 6px #222;
  z-index: 2;
}

#offcanvasNavbar {
  background-image: url("./images/offcanvas.jpg");
  background-size: cover;
  background-position: center bottom;
}

#offcanvasNavbar .nav-link {
  text-shadow: 0 2px 5px #fff;
}

.footer {
  background-color: #1e2e3d;
  color: #eee;
}

.footer a {
  text-decoration: none;
  color: #eee;
}

.nav-link {
  font-size: 2rem;
}

.logo {
  transition: height 0.2s ease;
}

.bigLogo {
  margin-left: -20px;
}

.pageFrame {
  border: 1px solid #222;
  background: rgba(245, 245, 245, 1);
  border-radius: 5px;
  box-shadow: 0 2px 4px #222;
  margin-left: 220px;
  width: calc(100vw - 254px);
  z-index: 1;
  position: relative;
  margin-top: 1rem;
}

.imgHover,
.buttonHover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.imgHover:hover {
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1, 1.1);
}

.buttonHover:hover {
  box-shadow: 0 2px 3px #222;
}

.myCarousel {
  height: 60vh;
}

.carouselContainer .f-button {
  --f-button-width: 38px;
  --f-button-height: 38px;
  --f-button-svg-width: 16px;
  --f-button-svg-height: 16px;
  --f-button-svg-stroke-width: 2.5;
  --f-button-bg: #fff;
  --f-button-color: rgb(71 85 105);
  --f-button-border-radius: 50%;
  --f-button-shadow: 0 6px 12px -2px rgb(50 50 93 / 25%),
    0 3px 7px -3px rgb(0 0 0 / 30%);
  --f-button-hover-bg: #f9f9f9;
  --f-button-active-bg: #f0f0f0;
}

.is-nav-selected {
  border: 4px solid #333;
  border-radius: 5px;
}

.quoteFormHeader {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #1e2e3d;
  margin-bottom: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.quoteForm {
  background: rgba(1, 1, 1, 0.2);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.formInput,
.errorFormInput {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #999;
  background-color: white;
  border-radius: 5px;
}

.errorFormInput {
  border: 2px solid red;
}

.waitMessage {
  font-size: 1.1rem;
  color: rgb(230, 0, 0);
  margin-bottom: 10px;
}

.charterSection {
  transform: rotateY(0deg);
  transition: transform 0.25s ease-in-out;
  backface-visibility: hidden;
}

.charterSection:not(.active) {
  transform: rotateY(90deg);
}

.charterSection.active {
  transform: rotateY(0deg);
}

@media (max-width: 389px) {
  .logo {
    height: 1.5rem !important;
  }
}

@media (min-width: 456px) and (max-width: 658px) {
  .myCarousel {
    height: 40vh;
  }
}

@media (max-width: 455px) {
  .myCarousel {
    height: 30vh;
  }
}

@media (max-width: 922px) {
  .header {
    display: none;
  }
  .header-mobile {
    display: block;
    position: fixed;
    width: 100%;
  }
  .pageFrame {
    margin-left: 10px;
    width: calc(100vw - 20px);
    margin-top: calc(44.5px + 1rem);
  }
}
